"use client";

import "./assets/style.css";
import { useEffect, useState } from "react";
import $ from "jquery"; // Import jQuery
import { Snow } from "@src/components/Games/Snow";
import { Box, Dialog } from "@mui/material";
import fetchClient from "@src/api/lib/fetch-client";
import useRouterClient from "@src/hooks/use-router-client";
import { ROUTES } from "@src/constants";
import { useResponsive } from "@src/hooks";
import styled from 'styled-components';
import { NextImage } from "@src/components";
const UseNowButton = ({
  message,
  errorMessage
}) => {
  const {
    router
  } = useRouterClient();
  const handleOnClick = () => {
    router.push(ROUTES.PRODUCT);
  };
  const ResponsiveText = styled.span`
    font-size: 12px;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  `;
  return <>
      <button onClick={!errorMessage ? handleOnClick : () => {}} className="play-button">
        <ResponsiveText data-sentry-element="ResponsiveText" data-sentry-source-file="snow.tsx">
          {errorMessage ? errorMessage : message}
        </ResponsiveText>
          <br></br>MUA SẮM NGAY
      </button>
      <style jsx>{`
        .play-button {
          width: 90%;
          min-width: 380px;
          height: 50px;
          align-self: center;
          text-align: center;
          background: #ab0116;
          color: white;
          border-radius: 20px;
          box-shadow: none;
          border: none;
          font-weight: bold;
          font-size: 12px;
          cursor: pointer;
          margin-top: 1rem;
          overflow: hidden;
        }

        .play-button:hover {
          background: rgb(200, 60, 55);
        }
      `}</style>
    </>;
};
const SnowComponent = () => {
  const {
    isMobile
  } = useResponsive();
  const [showSnow, setShowSnow] = useState(false);
  const [voucherImage, setVoucherImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    getGameConfig();
  }, []);
  useEffect(() => {
    if (showSnow) {
      $("body .snow").on("click", function () {
        if (!$(this).hasClass("special-snow")) {
          $(this).css("display", "none");
        } else {
          $(".snow").css("display", "none");
          getGift();
        }
      });
    }
  }, [showSnow]);
  const getGift = async () => {
    setOpen(true);
    setShowSnow(false);
    const response = await fetchClient({
      method: "GET",
      url: "/minigame-config/get-gift",
      config: {
        deviceAuthenticated: true
      }
    });
    if (response.data.error) {
      setTimeout(() => {
        setErrorMessage(response.data.message);
      }, 2000);
    } else {
      setTimeout(() => {
        setMessage(response.data.message);
        setVoucherImage(response.data.url);
      }, 2000);
    }
  };
  const getGameConfig = async () => {
    const response = await fetchClient({
      method: "GET",
      url: "/minigame-config",
      config: {
        deviceAuthenticated: true
      }
    });
    setShowSnow(!response.data.game_played);
  };
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  return <div id="snow" data-sentry-component="SnowComponent" data-sentry-source-file="snow.tsx">
      <Dialog maxWidth="md" open={open} onClose={onClose} aria-labelledby="popup-home" aria-describedby="popup-home" sx={{
      "& .MuiPaper-root": {
        backgroundColor: "transparent !important",
        boxShadow: "none",
        margin: "16px !important"
      }
    }} data-sentry-element="Dialog" data-sentry-source-file="snow.tsx">
        <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        zIndex: 9999,
        marginBottom: "8px",
        visibility: loaded ? "visible" : "hidden"
      }} data-sentry-element="Box" data-sentry-source-file="snow.tsx"></Box>
        {voucherImage && <NextImage src={voucherImage} sx={{
        width: "100%",
        height: "100%",
        "& img": {
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100%",
          maxHeight: "80vh",
          position: "relative !important"
        }
      }} loading="eager" onLoad={() => {
        setLoaded(true);
      }} />}
        {voucherImage && <UseNowButton errorMessage={errorMessage} message={message} />}
        {!voucherImage && <NextImage unoptimized src={"/assets/images/santa.gif"} sx={{
        width: "100%",
        height: "100%",
        "& img": {
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100%",
          maxHeight: "80vh",
          position: "relative !important"
        }
      }} loading="eager" onLoad={() => {
        setLoaded(true);
      }} />}
        {!voucherImage && <strong id="santa-title" style={{
        color: "white",
        textAlign: "center",
        fontSize: "16px",
        overflow: "hidden"
      }}>
            Bạn chờ ông già Noel lấy quà nhé
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </strong>}
      </Dialog>
      {showSnow && <Snow particles={isMobile ? 40 : 30} size={isMobile ? "60px" : "120px"} />}
    </div>;
};
export default SnowComponent;